<template>
  <div>
    <el-card>
      <el-steps :active="activeStep" align-center finish-status="success">
        <el-step :title="$t('form.zoo.basicTicketSettings')"></el-step>
        <el-step :title="$t('form.zoo.manageTickets')"></el-step>
      </el-steps>
      <el-divider />
      <div>
        <basic-info-add v-if="activeStep === 0" @goNext="goNext"></basic-info-add>
        <ticketsType v-if="activeStep === 1 && (ticketType === 'ticket' || ticketType === 'add_on')"></ticketsType>
        <ticketsPackage v-if="activeStep === 1 && ticketType === 'package'"></ticketsPackage>
        <ticketsMutiPackgae v-if="activeStep === 1 && ticketType === 'multi_park'"></ticketsMutiPackgae>
      </div>
    </el-card>
  </div>
</template>

<script>

import ticketsMutiPackgae from '@/views/zooTicketsManager/tickets/components/ticketsMutiPackgae.vue'
import ticketsPackage from '@/views/zooTicketsManager/tickets/components/ticketsPackage.vue'
import ticketsType from '@/views/zooTicketsManager/tickets/components/ticketsType.vue'
import basicInfoAdd from '@/views/zooTicketsManager/tickets/add/basicInfoAdd/index.vue'

export default {
  components: { basicInfoAdd, ticketsPackage, ticketsType, ticketsMutiPackgae },
  data () {
    return {
      activeStep: 0,
      ticketType: null,
      id: null
    }
  },
  mounted () {
    if (this.$route.params.ticketType) {
      this.activeStep = 1
      this.ticketType = this.$route.params.ticketType
    }
  },
  methods: {
    goNext (data) {
      this.activeStep = data.activeStep
      this.ticketType = data.ticketType
      this.$router.push({
        params: {
          ticket_package_id_eq: data.ticket_package_id_eq,
          ticketType: data.ticketType
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
