// 组件
// import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
import ticketsPackage from '@/views/zooTicketsManager/tickets/components/ticketsPackage.vue'
import ticketsType from '@/views/zooTicketsManager/tickets/components/ticketsType.vue'

// 接口
import { postZooTicketsFormData, getSiteCollection } from '@/services/zoo-tickets.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
// import constant from '@/utils/constant.js'

export default {
  // props: {
  //   activeStep: {
  //     type: Number,
  //     default () {
  //       return 0
  //     }
  //   }
  // },
  // provide () {
  //   return {
  //     baseData: this.baseData
  //   }
  // },
  components: {
    // importImg,
    alterForm,
    ticketsPackage,
    ticketsType
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.next'),
      baseData: {
        data: {}
      }
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    handleCancelClick () {
      this.$router.back(-1)
    },
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getSiteCollection().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[4].options = res.data.collections.sites
          this.formData[0].createDateItem[5].options = res.data.collections.sites
          // this.formData[0].createDateItem[4].options = res.data.collections.sites
          this.formData[0].createDateItem[3].options = res.data.collections.package_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop, item, creatData) {
      if (item.prop === 'package_type') {
        creatData.site_id = ''
        creatData.related_site_ids = []
      }
    },
    formCustomSubmit () {
      this.$refs.basicAlterForm.handleSubmitClick()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        ticket_package: obj
      }
      this.ticketType = params.ticket_package.package_type
      // params.ticket_package.remote_image_url = params.ticket_package.image_url
      // delete params.ticket_package.image_url
      // params.site_label.remote_image_url = params.site_label.image
      // delete params.site_label.image
      // Object.keys(params.site_label).forEach(res => {
      //   if (typeof params.site_label[res] === 'boolean') {
      //     if (params.site_label[res] === true) params.site_label[res] = '1'
      //     if (params.site_label[res] === false) params.site_label[res] = '0'
      //   }
      // })
      postZooTicketsFormData(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          // this.baseData.data = res.data.ticket_package.basic_information
          this.$emit('goNext', { activeStep: 1, ticketType: this.ticketType, ticket_package_id_eq: res.data.ticket_package.basic_information.id.value })
          // setTimeout(() => {
          //   this.$router.push({
          //     name: 'zooTicketsList'
          //   })
          // }, constant.ROUTER_CHANGE_INTERVAL)

          // this.$router.push({
          //   name: 'flexiComboroductList',
          //   query: {
          //     id: res.data.flexi_combo.id,
          //     shop_id: res.data.flexi_combo.shop_id
          //   }
          // })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
