<template>
  <div>
    <el-card>
      <div>
        <alter-form labelWidth="140px" :watchImageVisible="false" ref="basicAlterForm" @handleChange="handleChange"  @formSubmit="formSubmit" :formData="formData" :collapseLength="collapseLength" :customeButton="true">
          <template #customeButton>
            <div class="margintop20">
              <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
              <el-button type="primary" @click="formCustomSubmit">{{ btnTxt }}</el-button>
            </div>
          </template>
        </alter-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import 'index';
</style>
