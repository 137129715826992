<template>
  <div>
    <tickets-type></tickets-type>
    <el-row class="margintop20" type="flex" justify="center">
      <el-button @click="handleClick">{{ $t('button.cancel') }}</el-button>
      <el-button type="primary" @click="handleClick">{{ $t('button.confirm') }}</el-button>
    </el-row>
  </div>
</template>

<script>
import ticketsType from '@/views/zooTicketsManager/ticketsType/list/index.vue'

export default {
  components: {
    ticketsType
  },
  methods: {
    handleClick () {
      this.$router.push({
        name: 'zooTicketsList'
      })
    }
  }
}
</script>

<style>

</style>
