// import * as hostWithdrawRequestsApi from '@/services/host-withdraw-requests.js'
import { getPackageTypeSetup, getTicketsTypeList, deleteZooTicketsType, toogleOnlineZooTicketsType } from '@/services/zoo-tickets.js'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
import Utils from '@/utils/Utils'

export default {
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0
    }
  },
  mounted () {
    const _this = this
    _this.queryData(getPackageTypeSetup)
  },
  activated () {
    if (this.$route.params.ticket_package_id_eq && this.searchFormData.length > 0) {
      this.searchFormData[1].disabled = true
    }
    if (this.isActive) this.queryData(getPackageTypeSetup)
    this.isActive = true
  },
  computed: {
    topVisible () {
      return Utils.isNull(this.$route.params.ticket_package_id_eq)
    }
  },
  methods: {
    queryOpearte () {
      if (!Utils.isNull(this.$route.params.ticket_package_id_eq)) {
        this.$refs.searchForm.createData.ticket_package_id_eq = Number(this.$route.params.ticket_package_id_eq)
        this.searchFormData[1].disabled = true
      }
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getTicketsTypeList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleNewTab (data) {
      const newpage = this.$router.resolve({
        name: this.topVisible ? 'zooTicketsTypeDetail' : 'basicTicketsTypeDetail',
        params: {
          id: data.id.value,
          ticket_package_id_eq: this.$route.params.ticket_package_id_eq
        }
      })
      window.open(newpage.href, '_blank')
    },
    // 点击操作按钮
    handleClick (type, data) {
      const _this = this
      if (type === 'create') {
        this.$router.push({
          name: this.topVisible ? 'zooTicketsTypeAdd' : 'basicTicketsTypeAdd',
          params: {
            ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
            disabled: this.$route.params.ticket_package_id_eq ? 'true' : 'false',
            ticketType: this.$route.params.ticketType
          }
        })
      } else if (type?.action === 'view') {
        _this.$router.push({
          name: this.topVisible ? 'zooTicketsTypeDetail' : 'basicTicketsTypeDetail',
          params: {
            id: data.id.value,
            ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
            ticketType: this.$route.params.ticketType
          }
        })
      } else if (type?.action === 'edit') {
        _this.$router.push({
          name: this.topVisible ? 'zooTicketsTypeEdit' : 'basicTicketsTypeEdit',
          params: {
            id: data.id.value,
            ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
            disabled: this.$route.params.ticket_package_id_eq ? 'true' : 'false',
            ticketType: this.$route.params.ticketType
          }
        })
      } else if (type?.action === 'destroy') {
        this.deleteClick(data, deleteZooTicketsType)
      }
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      console.log('----data-----', data)
      if (jsonItemData.prop === 'active') {
        if (rowData[jsonItemData.prop].value) {
          _this.setGlobalLoading(true)
          toogleOnlineZooTicketsType(rowData.id.value, 'online').then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        } else {
          _this.setGlobalLoading(true)
          toogleOnlineZooTicketsType(rowData.id.value, 'offline').then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        }
      }
    },
    forwardToPage (data, name) {
      if (data.model_name && data.model_name === 'site') {
        this.$router.push({
          name: 'attractionsDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
