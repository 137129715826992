// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'attraction',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'ticket_package',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'listing_price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'original_price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'third_party_platform',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'parent_ticket_type',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'ranking',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'active',
    label: '',
    Object: 'value',
    width: '80'
  }]
}
