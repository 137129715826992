import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'active',
      name: 'switch',
      value: true,
      label: i18n.t('form.zoo.active'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.zoo.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.zoo.name2'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'package_type',
      name: 'select',
      label: i18n.t('form.zoo.ticket_type2'),
      options: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        nextChange: true
      }
    }, {
      span: 12,
      prop: 'site_id',
      name: 'select',
      label: i18n.t('form.zoo.site'),
      options: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        visible: 'package_type',
        visibleValue: 'ticket,add_on'
      }
    }, {
      span: 12,
      prop: 'related_site_ids',
      name: 'select',
      label: i18n.t('form.zoo.site2'),
      options: [],
      value: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true,
        visible: 'package_type',
        visibleValue: 'package,multi_park'
      }
    }, {
      span: 12,
      prop: 'pax',
      name: 'input',
      type: 'number',
      label: i18n.t('form.zoo.pax'),
      value: '',
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        visible: 'package_type',
        visibleValue: 'multi_park'
      }
    }, {
      span: 12,
      prop: 'location',
      name: 'input',
      label: i18n.t('form.zoo.location'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'terms_and_conditions',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.zoo.terms_and_conditions'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'short_desc',
      name: 'input',
      label: i18n.t('form.zoo.description'),
      placeholder: i18n.t('placeholder.defaultInput'),
      type: 'textarea',
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        limit: 100,
        imageName: 'image_url'
      }
    }]
  }]
}
